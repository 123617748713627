



















































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Action, Getter} from 'vuex-class';
import {QuizActions} from '@/modules/quiz/store/actions';
import {QuizGetters} from '@/modules/quiz/store/getters';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import QuizModule from '@/modules/quiz';
import {QUIZ_SERVICE} from '@/shared/services/quiz.service';
import RsCheckbox from '@/shared/components/fields/checkbox.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import RsModal from '@/shared/components/modal/modal.vue';
import {UUID} from '@/shared/constants/UUID';
import {IApiTalentQuizQuestion} from '@/shared/models/IApiTalentQuizQuestion';
import {QuizRouter} from '@/modules/quiz/router';
import RsLoading from '@/shared/components/loading/loading.vue';
import {QUIZ_STATES} from '@/shared/constants/QUIZ_STATES';
import {AssesmentsRouter} from '@/modules/dashboard/modules/assesments/router';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import {Icons} from '@/shared/components/icon/icons';

interface QuizStartForm {
  nohelp: boolean;
}

@Component({
  components: {RsLinkIcon, RsLoading, RsModal, RsButton, RsCheckbox, RsIcon},
  validations: {
    form: {
      nohelp: {
        checked: (val) => !!val
      }
    }
  }
})
export default class QuizQuiz extends Vue {
  @Action(QuizActions.START_QUIZ, {namespace: QuizModule.namespace})
  startQuiz: (id: UUID) => Promise<IApiTalentQuizQuestion>;
  @Action(QuizActions.CONTINUE_QUIZ, {namespace: QuizModule.namespace})
  continueQuiz: (id: UUID) => void;

  @Getter(QuizGetters.GET_QUIZZES, {namespace: QuizModule.namespace}) quizzes: IApiTalentQuiz[];

  AssesmentsRouter = AssesmentsRouter;
  Icons = Icons;

  id: UUID | null = null;

  warningModal = false;

  starting = false;

  form: QuizStartForm = {
    nohelp: false,
  }

  get quiz(){
    if(this.id && this.quizzes.length){
      const quiz = this.quizzes.find((q) => q.quizId === this.id);
      if(quiz){
        const state = QUIZ_SERVICE.quizState(quiz);
        if(state === QUIZ_STATES.PROGRESS && quiz.userQuizId){
          this.continueQuiz(quiz.userQuizId);
          this.$router.push({name: QuizRouter.QUESTION, params: this.$route.params});
        }
        else if(state === QUIZ_STATES.PASSED && quiz.userQuizId){
          this.continueQuiz(quiz.userQuizId);
          this.$router.push({name: QuizRouter.RESULTS, params: this.$route.params});
        }
        else if(state === QUIZ_STATES.FAILED){
          this.$router.push({name: AssesmentsRouter.INDEX})
        }
      }
      return quiz;
    }
    return undefined;
  }

  roleData(quiz: IApiTalentQuiz){
    return QUIZ_SERVICE.roleData(quiz);
  }

  startAssesment(){
    this.warningModal = false;
    if(this.id){
      this.starting = true;
      this.startQuiz(this.id)
        .then(() => {
          this.$track.sendEvent('QuizStart', {id: this.quiz?.quizId, name: this.quiz?.name})
          this.$router.push({name: QuizRouter.QUESTION, params: this.$route.params});
        })
    }
  }

  cancelAssesment(){
    this.$router.push({name: AssesmentsRouter.INDEX})
  }

  mounted() {
    this.id = this.$route.params.quizId;
  }
}
