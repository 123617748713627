














import { Vue, Component, Prop } from 'vue-property-decorator';
import RsIcon from '@/shared/components/icon/icon.vue';
import { Icons } from '@/shared/components/icon/icons';

@Component({
  name: 'rs-link-icon',
  components: { RsIcon }
})
export default class RSLinkIcon extends Vue {
  @Prop({ required: false, default: Icons.ARROW_RIGHT }) icon?: string;
  @Prop({ required: false, default: false }) back?: boolean;
}
