import {Icons} from '@/shared/components/icon/icons';

export enum DeveloperRoleType {
  FRONTEND = 'FRONTEND',
  BACKEND = 'BACKEND',
  FULLSTACK = 'FULLSTACK',
  MOBILE = 'MOBILE',
  DEVOPS = 'DEVOPS',
  DATA_SCIENCE = 'DATA_SCIENCE',
  DATA_ENGINEER = 'DATA_ENGINEER',
  BLOCKCHAIN_ETH = 'BLOCKCHAIN_ETH',
  MACHINE_LEARNING = 'MACHINE_LEARNING',
  QA_ENGINEER = 'QA_ENGINEER',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  PRODUCT_MANAGER = 'PRODUCT_MANAGER',
  UI_UX_DESIGNER = 'UI_UX_DESIGNER',
  DATA_ANALYST = 'DATA_ANALYST',
}

export interface DeveloperRoleInfo {
  icon: Icons;
  name: string;
}

export const rolesInfo: Record<DeveloperRoleType, DeveloperRoleInfo> = {
  [DeveloperRoleType.FRONTEND]: {
    icon: Icons.FRONTEND,
    name: 'Frontend',
  },
  [DeveloperRoleType.BACKEND]: {
    icon: Icons.BACKEND,
    name: 'Backend',
  },
  [DeveloperRoleType.FULLSTACK]: {
    icon: Icons.FULLSTACK,
    name: 'Fullstack',
  },
  [DeveloperRoleType.MOBILE]: {
    icon: Icons.MOBILE,
    name: 'Mobile',
  },
  [DeveloperRoleType.DEVOPS]: {
    icon: Icons.DEVOPS,
    name: 'DevOps',
  },
  [DeveloperRoleType.DATA_SCIENCE]: {
    icon: Icons.DATASCIENCE,
    name: 'Data science',
  },
  [DeveloperRoleType.DATA_ENGINEER]: {
    icon: Icons.DATAENGINEER,
    name: 'Data Engineer',
  },
  [DeveloperRoleType.MACHINE_LEARNING]: {
    icon: Icons.MACHINE_LEARNING,
    name: 'Machine learning',
  },
  [DeveloperRoleType.BLOCKCHAIN_ETH]: {
    icon: Icons.BLOCKCHAIN,
    name: 'Blockchain',
  },
  [DeveloperRoleType.QA_ENGINEER]: {
    icon: Icons.QA,
    name: 'Quality Assurance',
  },
  [DeveloperRoleType.PROJECT_MANAGER]: {
    icon: Icons.PROJECT_MANAGER,
    name: 'Project Manager',
  },
  [DeveloperRoleType.PRODUCT_MANAGER]: {
    icon: Icons.PRODUCT_MANAGER,
    name: 'Product Manager',
  },
  [DeveloperRoleType.UI_UX_DESIGNER]: {
    icon: Icons.UI_DESIGNER,
    name: 'UI/UX Designer',
  },
  [DeveloperRoleType.DATA_ANALYST]: {
    icon: Icons.DATA_ANALYST,
    name: 'Data Analyst',
  },
}
