






import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'rs-loading',
})
export default class RSLoading extends Vue {
  @Prop({default: '100px'}) height?: string;
  @Prop({default: '100%'}) width?: string;
  @Prop() additionalStyle?: Record<string, any>;
}
