












import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'rs-checkbox',
})
export default class RSCheckbox extends Vue {
  @Prop({required: false}) val: any;
  @Prop({required: true}) value: any;

  checkedProxy = false

  get checked(){
    return this.value;
  }

  set checked(val){
    this.checkedProxy = val
  }
}
