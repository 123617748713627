import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import {DeveloperRoleInfo, rolesInfo} from '@/shared/constants/ROLES';
import {QUIZ_STATES} from '@/shared/constants/QUIZ_STATES';

class QuizService {
  roleData(quiz: IApiTalentQuiz): DeveloperRoleInfo{
    const role = quiz.roles[0];
    return rolesInfo[role];
  }

  quizState(quiz: IApiTalentQuiz): QUIZ_STATES {
    if(quiz.comingSoon){
      return QUIZ_STATES.COMING_SOON;
    }
    if(quiz.inProgress){
      return QUIZ_STATES.PROGRESS;
    }
    if(quiz.passed){
      return QUIZ_STATES.PASSED;
    }
    if(quiz.passed === false && quiz.results && !quiz.canStart){
      return QUIZ_STATES.FAILED
    }
    return QUIZ_STATES.AVAILABLE;
  }
}

export const QUIZ_SERVICE = new QuizService();
